<template>
  <div class="bg-primary-950 text-white">
    <app-page-wrapper class="relative flex h-full items-center justify-center">
      <div class="flex flex-wrap items-center gap-x-4 gap-y-2 pr-8 md:pr-0">
        <p class="balance text-sm font-semibold md:text-base">
          <strong class="font-bold text-deco2">{{ news.title }}</strong> -
          {{ news.message }}
        </p>

        <a
          v-if="news.cta_label && news.cta_url"
          class="rounded bg-white p-1 px-2 text-sm font-semibold text-primary-950"
          :href="ctaUrlWithProtocol"
          target="_blank"
          @click="onCtaClick"
        >
          {{ news.cta_label }}
        </a>
      </div>

      <button
        class="absolute right-6 top-1/2 -translate-y-1/2 text-white before:absolute before:-inset-2 before:-z-[1] before:content-[''] md:right-10"
        @click="onClose"
      >
        <app-icon icon="ph:x" :size="20" />
      </button>
    </app-page-wrapper>
  </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
  news: {
    id: string;
    title: string;
    message: string;
    cta_label?: string;
    cta_url?: string;
  };
}>();

const emit = defineEmits(["close"]);

const ctaUrlWithProtocol = computed(() => {
  if (!properties.news.cta_url) return;

  return /^https?:\/\//i.test(properties.news.cta_url)
    ? properties.news.cta_url
    : `https://${properties.news.cta_url}`;
});

const { $analytics } = useNuxtApp();

if (import.meta.client) {
  $analytics.track("news_banner_viewed", properties.news);
}

function onClose() {
  $analytics.track("news_banner_closed", properties.news);
  return emit("close");
}

function onCtaClick() {
  $analytics.track("news_banner_clicked", properties.news);
}
</script>
